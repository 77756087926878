<template>
    <v-container>
        <div
            class="h5 mb-4 text-gray-700"
        >
            {{ $t('companyMissions.title') }}
        </div>

        <transition-group
            name="slide-fade"
            tag="p"
        >
            <div
                v-for="group in groupsNotEmpty"
                :key="group.id"
                style="display: grid; grid-template-columns: 90% 10%"
                class="mb-4 pb-0"
            >
                <div class="h5 mb-0 pb-0">
                    {{ group.name }}
                </div>

                <purposeGroupSingle
                    class="mt-0 pt-0"
                    :purpose="group"
                    style="grid-column: 1 / 3"
                />

                <v-divider
                    class="mx-6"
                    style="grid-column: 1 / 3"
                />
            </div>
        </transition-group>

        <!--         my Purpose Cloud-->
        <transition
            name="slide-fade-y-up"
        >
            <div
                v-if="myPurposeCloud !== undefined"
                style="display: grid; grid-template-columns: 100% "
                class="mb-4 pb-0"
            >
                <div class="h5 mb-0 pb-0">
                    {{ $t('companyMissions.myMission') }}
                </div>
                <purposeGroupSingle
                    class="mt-0 pt-0"
                    :purpose="myPurposeCloud"
                    style="grid-column: 1 / 3"
                />
            </div>
        </transition>

        <div
            v-if="showFinalHelp"
            class="h6 mb-4 text-gray-700"
            style="display: grid; grid-template-columns: minmax(60px, 25%) auto;"
        >
            <div>
                <v-img
                    src="@/assets/img/purposes/mujer_up.png"
                    contain
                />
            </div>
            <pre style="white-space: pre-line;justify-self: left;margin-top: 10px">
            {{
                $t('companyMissions.description')
            }}
            </pre>
        </div>

        <nextPostBtn :url-next-post="nextPost" />
    </v-container>
</template>

<script>
import purposeGroupSingle from '@/components/purpose/purposeGroupSingle'
import axios from '@/plugins/axios'
import postMixin from '@/mixins/postMixin'
import nextPostBtn from '@/components/posts/nextPostBtn'

export default {
    name: 'CompanyMissions',
    components: { purposeGroupSingle, nextPostBtn },
    mixins: [postMixin],
    data() {
        return {
            groups: [],
            myPurposeCloud: undefined
        }
    },
    computed: {
        groupsNotEmpty() {
            return this.groups.filter(g => g.body || g.attributes?.body);
        },
        showFinalHelp() {
            const g = this.myPurposeCloud;
            // if have groups missions en the tree AND dont have defines user missions
            return this.groupsNotEmpty.length && (!g || !(g.body || g.attributes?.body));
        }
    },
    created() {
        this.fetchMyGroupsHierarchy();
        this.fetchPurposeCloud();
    },
    methods: {
        async fetchMyGroupsHierarchy() {
            try {
                //  this.$store.dispatch('loading/start');

                /* const purposes = await axios
            .get('/indexPurposesCLoud') */

                const groups = await axios({
                    url: '/myGroupsHierarchy'
                });

                this.groups = groups.data
            } catch (e) {
                console.log('Error: ')
                console.log(e)
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                //  this.$store.dispatch('loading/end');
            }
        },

        async fetchPurposeCloud() {
            try {
                this.$store.dispatch('loading/start');

                const myPurposeCloud = await axios
                    .get('/purposesCloud', {
                        params: {
                            'filter[my]': 1
                        }
                    });

                /* console.log('myPurposeCloud.data.data')
          console.log(myPurposeCloud.data.data) */
                this.myPurposeCloud = myPurposeCloud.data.data && myPurposeCloud.data.data.length
                    ? myPurposeCloud.data.data[0] : undefined;
            } catch (e) {
                this.$store.dispatch('snackbar/error', this.$t('common.errorLoadingData'))
            } finally {
                this.$store.dispatch('loading/end');
            }
        }
    }
}
</script>

<style scoped>

</style>
